<template>
  <div class="hide"></div>
</template>

<script>
export default {
  mounted: function () {
    this.logout()
  },

  methods: {
    logout() {
      let self = this
      this.$store.dispatch("logout").then(() => {
        self.$router.push({ name: "login" }).catch((err) => {})
      })
    },
  },
}
</script>
